<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农场管理</el-breadcrumb-item>
      <el-breadcrumb-item>农事任务</el-breadcrumb-item>
    </el-breadcrumb> -->
      <a-row :gutter="10">
        <a-col :span="showRight?16:24">
          <el-card :style="{'min-height': screenHeight+'px'}">
            <div>
              <a-row>
                <a-col :span="10">
                  <div>
                    年份:
                    <el-date-picker
                        v-model="selectYear"
                        @change="handleSearch"
                        style="width: 150px"
                        type="year"
                        size="small"
                        value-format="yyyy"
                        placeholder="选择年">
                    </el-date-picker>
                    <el-button @click="handleSearch" style="margin-left: 10px" size="small">
                      搜索
                    </el-button>
                  </div>
                </a-col>
                <a-col :span="14">
                  <div style="float: right">
                    <template>
                      <a-dropdown style="margin-left: 15px">
                        <a class="ant-dropdown-link dropdown_class" @click="e => e.preventDefault()">
                          农作物
                          <a-icon type="down"/>
                        </a>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="item in plantVarietyList" :key="item.id"
                                       @click="query.plantVarietyId == item.id?(query.plantVarietyId =''):(query.plantVarietyId = item.id)">
                            <a href="javascript:;"
                               :style="{backgroundColor: query.plantVarietyId==item.id?'rgba(24,144,255,0.23)':''}">{{item.name}}</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </template>
                    <template>
                      <a-dropdown style="margin-left: 15px">
                        <a class="ant-dropdown-link dropdown_class" @click="e => e.preventDefault()">
                          劳作方式
                          <a-icon type="down"/>
                        </a>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="item in plantWayList" :key="item.id"
                                       @click="query.plantWayId == item.id?(query.plantWayId =''):(query.plantWayId = item.id)">
                            <a href="javascript:;"
                               :style="{backgroundColor: query.plantWayId==item.id?'rgba(24,144,255,0.23)':''}">
                              {{item.way}}</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </template>
                    <template>
                      <a-dropdown style="margin-left: 15px">
                        <a class="ant-dropdown-link dropdown_class" @click="e => e.preventDefault()">
                          状态
                          <a-icon type="down"/>
                        </a>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="item in queryStateList" :key="item.id"
                                       @click="query.state == item.id?(query.state =''):(query.state = item.id)">
                            <a href="javascript:;"
                               :style="{backgroundColor: query.state==item.id?'rgba(24,144,255,0.23)':''}">{{item.text}}</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </template>
                    <template>
                      <a-dropdown style="margin-left: 15px">
                        <a class="ant-dropdown-link dropdown_class" @click="e => e.preventDefault()">
                          分组
                          <a-icon type="down"/>
                        </a>
                        <a-menu slot="overlay">
                          <a-menu-item v-for="item in querygroupByList" :key="item.id"
                                       @click="query.groupBy == item.id?(query.groupBy ='TIME'):(query.groupBy = item.id)">
                            <a href="javascript:;"
                               :style="{backgroundColor: query.groupBy==item.id?'rgba(24,144,255,0.23)':''}">{{item.text}}</a>
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </template>
                    <el-button @click="addCropManagement" v-auth="'cropManagement-add'"
                               style="margin-left: 10px;background-color: #386099;color: #FFF;border: 0px" size="small">
                      新增
                    </el-button>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div style="margin-top: 10px"/>
            <div :style="{'height': screenHeight-135+'px',overflow:'auto'}">
              <div v-for="(value,key) in data" :key="key" >
                <div style="background-color: #f8f8f8;padding: 5px">
                  <span style="margin-left: 1rem">{{key}}</span>
                  <span style="margin-left: 0.6rem">
                 <a-badge :count="value.length" :number-style="{ backgroundColor: '#5bc0de' }"/>
              </span>
                </div>
                <div style="margin-top: 10px"></div>
                <a v-for="val in value" :key="val.id" @click="showCropManagement(val)"
                   style="background-color: #ff5500">
                  <div
                      :style="{padding: '3px',backgroundColor: cropManagementForm.id === val.id?'rgba(72,120,188,0.18)':''}">
                    <a-row>
                      <a-col :span="6">
                  <span style="margin-left: 2rem">
                    {{val.plantWayName}}
                  </span>
                      </a-col>
                      <a-col :span="8">
                        <div style="width:100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          {{val.plantSeedingsName}}
                        </div>
                      </a-col>
                      <a-col :span="10">
                        <div style="float: right">
                    <span>
                      <a-tag :color="val.state | StateColorFiltr">{{val.state | StateFiltr}}</a-tag>
                      </span>
                          <span style="margin-left: 0.1rem">
                      {{val.startTime | dateFormatFull}}
                    </span>
                          <span style="margin-left: 1.3rem">
                            <span v-for="user in val.workersList" :key="user.id">
                               <a-avatar v-if="user.imgPathAll === null || user.imgPathAll === ''" style="color: #f56a00; backgroundColor: #fde3cf">
                      {{user.username.substring(0,1)}}
                    </a-avatar>
                              <a-avatar v-else :src="user.imgPathAll" />
                            </span>
                      </span>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a>
              </div>
              <div v-if="totalCount==0">
                <a-empty style="margin-top: 100px" />
              </div>
            </div>
            <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10,20,30,40]"
                :current-page="currentPage"
                :page-size="pageSize"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
                :total="totalCount">
            </el-pagination>
          </el-card>
        </a-col>
        <a-col :span="showRight?8:0">
          <el-card v-if="rightType=='show'" :style="{'min-height': screenHeight+'px'}">
            <div slot="header" class="clearfix">
              <span>农事任务</span>
              <a href="#" @click="showRight=false" style="color: black">
                <a-icon style="float: right; padding: 3px 0" type="close"/>
              </a>
            </div>
            <div :style="{'height': screenHeight-135+'px',overflow:'auto'}">
              <a-tag style="font-size: 13px">{{cropManagementForm.plantSeedingsName}}</a-tag>
              <div style="margin: 15px 15px 15px 5px;font-size: 25px">{{cropManagementForm.plantWayName}}
                <a-tag :color="cropManagementForm.state | StateColorFiltr"
                       style="position: relative;bottom: 3px;margin-left: 5px">{{cropManagementForm.state | StateFiltr}}
                </a-tag>
              </div>
              <el-divider content-position="left">劳作时间</el-divider>
              <div>
                {{cropManagementForm.time[0] | dateFormatFull}} - {{cropManagementForm.time[1] | dateFormatFull}}
              </div>
              <el-divider content-position="left">所需农资</el-divider>
              <a-row v-for="(item,index) in cropManagementForm.assetWarehouseAndDosageList" :key="item.id"
                     class="assetWarehouseAndDosage_look_class">
                <a-col :span="14">
                  <span>农资:</span>
                  <a-select placeholder="选择农资" style="width: 55%;margin-left: 5px" v-model="item.assetWarehouse" disabled>
                    <a-select-option :value="aw.id" v-for="aw in assetWarehouseList" :key="aw.id">
                      {{aw.name}}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="10">
                  <span> 亩用量:</span>
                  {{item.dosage}}
                </a-col>
              </a-row>
              <el-divider content-position="left">基础数据</el-divider>
              <div>
                <span style="width: 25%">工作方式:</span>

                {{cropManagementForm.workWay=='MACHINE'?'机器':'人工'}}
              </div>
              <div class="assetWarehouseAndDosage_look_class" style="margin-top: 5px">
                <span style="width: 25%">工人:</span>
                <a-select mode="multiple" placeholder="请选择工人" style="width: 75%;margin-left: 5px"
                          v-model="cropManagementForm.workersList" disabled>
                  <a-select-option :value="item.id" v-for="item in userList" :key="item.id">
                    {{item.username}}
                  </a-select-option>
                </a-select>
              </div>
              <el-divider content-position="left">操作记录</el-divider>
              <div>
                <div v-for="item in logData" :key="item.id">
                  {{item.peopleName}} {{item.time | dateFormatFull}}{{item.nowState | StateFiltrO}}任务
                </div>
              </div>
            </div>
            <div
                :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
           width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1,
        }"
            >
              <el-button @click="toEditCropManagementState('WORKING')" v-auth="'cropManagement-start'"
                         style="background-color: #ec971f;color: #FFF;border: 0px" size="small"
                         v-if="cropManagementForm.state === 'CREATE' || cropManagementForm.state === 'CANCEL'">
                开始
              </el-button>
              <el-button @click="toEditCropManagementState('FINISH')" v-auth="'cropManagement-finish'"
                         style="background-color: #449d44;color: #FFF;border: 0px" size="small"
                         v-if="cropManagementForm.state === 'WORKING'">
                完成
              </el-button>
              <el-button @click="toEditCropManagementState('CANCEL')" v-auth="'cropManagement-cancel'"
                         style="background-color: #d53e3a;color: #FFF;border: 0px" size="small"
                         v-if="cropManagementForm.state === 'WORKING'">
                取消
              </el-button>
              <el-button @click="toDeleteCropManagement" v-auth="'cropManagement-delete'"
                         style="background-color: #d53e3a;color: #FFF;border: 0px" size="small"
                         v-if="cropManagementForm.state != 'FINISH'&&cropManagementForm.state != 'WORKING'">
                删除
              </el-button>
              <el-button @click="editCropManagementForm" v-auth="'cropManagement-edit'"
                         style="background-color: #386099;color: #FFF;border: 0px" size="small"
                         v-if="cropManagementForm.state != 'FINISH'">
                修改
              </el-button>
            </div>
          </el-card>
          <el-card v-else-if="rightType=='edit'" :style="{'min-height': screenHeight+'px'}">
            <div slot="header" class="clearfix">
              <span>编辑农事任务</span>
              <a href="#" @click="showRight=false" style="color: black">
                <a-icon style="float: right; padding: 3px 0" type="close"/>
              </a>
            </div>
            <div :style="{'height': screenHeight-135+'px',overflow:'auto'}">
              <a-form-model class="login_form" ref="loginRefForm"
                            :model="cropManagementForm"
                            :rules="rules"
                            :labelAlign="'left'"
              >
                <div class="cropManagementForm_header">
                  <a-form-model-item prop="plantSeedingsId" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-select placeholder="输入种植批次" style="width: 100%" v-model="cropManagementForm.plantSeedingsId">
                      <a-select-option :value="item.id" v-for="item in plantSeedlingsList" :key="item.id">
                        {{item.code}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="plantWayId" prop="referenceValue" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-select placeholder="选择劳作方式" style="width: 100%" v-model="cropManagementForm.plantWayId">
                      <a-select-option :value="item.id" v-for="item in plantWayList" :key="item.id">
                        {{item.way}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
                <div>
                  <el-divider content-position="left">劳作时间</el-divider>
                  <el-date-picker
                      style="width: 100%"
                      size="small"
                      v-model="cropManagementForm.time"
                      type="datetimerange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </div>
                <div>
                  <el-divider content-position="left">所需农资
                  </el-divider>
                  <a-form-model-item prop="assetWarehouseAndDosageList" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-row v-for="(item,index) in cropManagementForm.assetWarehouseAndDosageList" :key="item.id">
                      <a-col :span="14">
                        <span>选择农资:</span>
                        <a-select placeholder="选择农资" style="width: 55%;margin-left: 5px" v-model="item.assetWarehouse">
                          <a-select-option :value="aw.id" v-for="aw in assetWarehouseList" :key="aw.id">
                            {{aw.name}}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="10">
                        <span> 亩用量:</span>
                        <a-input style="width: 35%;margin-left: 5px" v-model="item.dosage" type="number" placeholder="请输入"/>
                        <div style="float: right">
                          <a>
                            <a-icon type="close-circle" class="need-asset-warehouse-delete"
                                    @click="removeOneAssetWarehouseAndDosage(index)"/>
                          </a>
                        </div>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <div style="display: flex;justify-content: center;margin-top: 8px">
                    <el-button @click="addAssetWarehouseAndDosage"
                               style="background-color: #67C23A;color: #FFF;border: 0px" size="small">
                      添加农资
                    </el-button>
                    <el-button @click="removeAllAssetWarehouseAndDosage"
                               style="background-color: #909399;color: #FFF;border: 0px" size="small">
                      清空农资
                    </el-button>
                  </div>
                  <div>
                    <!--<button>添加农资</button>-->
                  </div>
                </div>
                <div>
                  <el-divider content-position="left">基础数据</el-divider>
                  <a-form-model-item prop="workWay" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <span style="width: 25%">工作方式:</span>
                    <a-select placeholder="请选择工作方式" style="width: 75%;margin-left: 5px"
                              v-model="cropManagementForm.workWay">
                      <a-select-option value="MACHINE">
                        机器
                      </a-select-option>
                      <a-select-option value="MANUAL">
                        人工
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="workersList" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <span style="width: 25%">选择工人:</span>
                    <a-select mode="multiple" placeholder="请选择工人" style="width: 75%;margin-left: 5px"
                              v-model="cropManagementForm.workersList">
                      <a-select-option :value="item.id" v-for="item in userList" :key="item.id">
                        {{item.username}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              </a-form-model>
            </div>
            <div
                :style="{
           position: 'absolute',
           right: 0,
           bottom: 0,
            width: '100%',
           borderTop: '1px solid #e9e9e9',
           padding: '10px 16px',
           textAlign: 'right',
           zIndex: 1,
         }"
            >
              <el-button @click="toEditCropManagement"
                         style="background-color: #386099;color: #FFF;border: 0px" size="small">
                保存
              </el-button>
            </div>
          </el-card>
          <el-card v-else="rightType=='add'" :style="{'min-height': screenHeight+'px'}">
            <div slot="header" class="clearfix">
              <span>新增农事任务</span>
              <a href="#" @click="showRight=false" style="color: black">
                <a-icon style="float: right; padding: 3px 0" type="close"/>
              </a>
            </div>
            <div :style="{'height': screenHeight-135+'px',overflow:'auto'}">
              <a-form-model class="login_form" ref="loginRefForm"
                            :model="cropManagementForm"
                            :rules="rules"
                            :labelAlign="'left'"
              >
                <div class="cropManagementForm_header">
                  <a-form-model-item prop="plantSeedingsId" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-select placeholder="输入种植批次" style="width: 100%" v-model="cropManagementForm.plantSeedingsId">
                      <a-select-option :value="item.id" v-for="item in plantSeedlingsList" :key="item.id">
                        {{item.code}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="plantWayId" prop="referenceValue" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-select placeholder="选择劳作方式" style="width: 100%" v-model="cropManagementForm.plantWayId">
                      <a-select-option :value="item.id" v-for="item in plantWayList" :key="item.id">
                        {{item.way}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
                <div>
                  <el-divider content-position="left">劳作时间</el-divider>
                  <el-date-picker
                      style="width: 100%"
                      size="small"
                      v-model="cropManagementForm.time"
                      type="datetimerange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                  </el-date-picker>
                </div>
                <div>
                  <el-divider content-position="left">所需农资
                  </el-divider>
                  <a-form-model-item prop="assetWarehouseAndDosageList" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <a-row v-for="(item,index) in cropManagementForm.assetWarehouseAndDosageList" :key="item.id">
                      <a-col :span="14">
                        <span>选择农资:</span>
                        <a-select placeholder="选择农资" style="width: 55%;margin-left: 5px" v-model="item.assetWarehouse">
                          <a-select-option :value="aw.id" v-for="aw in assetWarehouseList" :key="aw.id">
                            {{aw.name}}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="10">
                        <span> 亩用量:</span>
                        <a-input style="width: 35%;margin-left: 5px" v-model="item.dosage" type="number" placeholder="请输入"/>
                        <div style="float: right">
                          <a>
                            <a-icon type="close-circle" class="need-asset-warehouse-delete"
                                    @click="removeOneAssetWarehouseAndDosage(index)"/>
                          </a>
                        </div>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <div style="display: flex;justify-content: center;margin-top: 8px">
                    <el-button @click="addAssetWarehouseAndDosage"
                               style="background-color: #67C23A;color: #FFF;border: 0px" size="small">
                      添加农资
                    </el-button>
                    <el-button @click="removeAllAssetWarehouseAndDosage"
                               style="background-color: #909399;color: #FFF;border: 0px" size="small">
                      清空农资
                    </el-button>
                  </div>
                  <div>
                    <!--<button>添加农资</button>-->
                  </div>
                </div>
                <div>
                  <el-divider content-position="left">基础数据</el-divider>
                  <a-form-model-item prop="workWay" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <span style="width: 25%">工作方式:</span>
                    <a-select placeholder="请选择工作方式" style="width: 75%;margin-left: 5px"
                              v-model="cropManagementForm.workWay">
                      <a-select-option value="MACHINE">
                        机器
                      </a-select-option>
                      <a-select-option value="MANUAL">
                        人工
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="workersList" :label-col="{ span: 0 }"
                                     :wrapper-col="{ span: 24 }">
                    <span style="width: 25%">选择工人:</span>
                    <a-select mode="multiple" placeholder="请选择工人" style="width: 75%;margin-left: 5px"
                              v-model="cropManagementForm.workersList">
                      <a-select-option :value="item.id" v-for="item in userList" :key="item.id">
                        {{item.username}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
              </a-form-model>
            </div>
            <div
                :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
           width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1,
        }"
            >
              <el-button @click="toAddCropManagement"
                         style="background-color: #386099;color: #FFF;border: 0px" size="small">
                保存
              </el-button>
            </div>
          </el-card>
        </a-col>
      </a-row>
  </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "CropManagement",
        data() {
            return {
                query: {
                    plantVarietyId: '',
                    plantWayId: '',
                    state: '',
                    groupBy: 'TIME',
                },
                queryStateList: [
                    {text: '新建', id: 'CREATE'},
                    {text: '进行中', id: 'WORKING'},
                    {text: '完成', id: 'FINISH'},
                    {text: '取消', id: 'CANCEL'},
                    {text: '超时', id: 'OVERTIME'},
                ],
                querygroupByList: [
                    {text: '时间', id: 'TIME'},
                    {text: '品种', id: 'PLANTVARIETY'},
                    {text: '状态', id: 'STATE'},
                    {text: '劳作方式', id: 'PLANTWAY'},
                ],
                cropManagementForm: {
                    id: '',
                    plantSeedingsId: [],
                    workWay: [],
                    plantWayId: [],
                    workersList: [],
                    workers: '',
                    time: [],
                    startTimeStr: '',
                    finishTimeStr: '',
                    state: [],
                    assetWarehouseAndDosageList: [{assetWarehouse: [], dosage: ''}],
                    assetWarehouseAndDosage: '',
                    plantSeedingsName: '',
                    plantWayName: '',
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号!', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码!', trigger: 'blur'},
                    ],
                },
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 115,//屏幕高度
                showRight: false,
                rightType: '',
                currentPage: 1,
                pageSize: 10,
                totalCount: 0,
                selectYear: this.moment(new Date()).format('YYYY'),
                plantWayList: [],
                assetWarehouseList: [],
                userList: [],
                plantSeedlingsList: [],
                plantVarietyList: [],
                data: [],
                logData: [],
            }
        },
        watch: {
            'showRight': function (val) {
                if (val === false) {
                    this.cleanCropManagementForm();
                }
            },
            'query.plantVarietyId': function (val) {
                this.showRight = false;
                this.getCropManagementData();
            },
            'query.plantWayId': function (val) {
                this.showRight = false;
                this.getCropManagementData();
            },
            'query.state': function (val) {
                this.showRight = false;
                this.getCropManagementData();
            },
            'query.groupBy': function (val) {
                this.showRight = false;
                this.getCropManagementData();
            },

        },
        filters: {
            StateColorFiltr: function (value) {
                if (!value) return "";
                if (value === 'CREATE') {
                    return "purple";
                } else if (value === 'WORKING') {
                    return "blue";
                } else if (value === 'FINISH') {
                    return "green";
                } else if (value === 'CANCEL') {
                    return "orange";
                } else if (value === 'OVERTIME') {
                    return "red";
                }
            },
            StateFiltr: function (value) {
                if (!value) return "";
                if (value === 'CREATE') {
                    return "新建";
                } else if (value === 'WORKING') {
                    return "进行中";
                } else if (value === 'FINISH') {
                    return "完成";
                } else if (value === 'CANCEL') {
                    return "取消";
                } else if (value === 'OVERTIME') {
                    return "超时";
                }
            },
            StateFiltrO: function (value) {
                if (!value) return "";
                if (value === 'CREATE') {
                    return "新建";
                } else if (value === 'WORKING') {
                    return "开始";
                } else if (value === 'FINISH') {
                    return "完成";
                } else if (value === 'CANCEL') {
                    return "取消";
                } else if (value === 'OVERTIME') {
                    return "超时";
                }
            },
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 115; //窗口高度
            };
        },
        created() {
            this.getCropManagementData();
            this.getPlantWayList();
            this.getAssetWarehouseList();
            this.getUserList();
            this.getPlantSeedingsList();
            this.getPlantVarietyList();
        },
        methods: {
            toDeleteCropManagement(){
                let self = this;
                this.$confirm({
                    title: '确定删除该农事任务吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        const {data: res} = await this.http.cropManagement.deleteCropManagement({id:this.cropManagementForm.id});
                        if (res.status === 200) {
                            self.$message.success(res.msg);
                            self.getCropManagementData();
                            self.showRight = false;
                        } else {
                            self.$message.warning(res.msg);
                            return false;
                        }
                    },
                    onCancel() {
                    },
                });
                this.http.cropManagement.deleteCropManagement({})
            },
            showCropManagement(obj) {
                this.rightType = 'show';
                this.getCropManagementLogList(obj.id);
                this.cleanCropManagementForm();
                this.cropManagementForm = {
                    id: obj.id,
                    plantSeedingsId: obj.plantSeedingsId,
                    workWay: obj.workWay,
                    plantWayId: obj.plantWayId,
                    assetWarehouseAndDosageList: JSON.parse(obj.assetWarehouseAndDosage),
                    workersList: obj.workers.split(","),
                    time: [this.moment(obj.startTime), this.moment(obj.finishTime)],
                    workers: '',
                    startTimeStr: '',
                    finishTimeStr: '',
                    state: obj.state,
                    assetWarehouseAndDosage: '',
                    plantSeedingsName: obj.plantSeedingsName,
                    plantWayName: obj.plantWayName
                };
                console.log(obj);
                this.showRight = true;
            },
            getCropManagementLogList(id){
                this.logData = [];
                this.http.cropManagement.getCropManagementLogList({id:id}).then(response => {
                    if (response.data.status == 200) {
                        this.logData = response.data.data;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },
            editCropManagementForm() {
                this.rightType = 'edit';
                this.showRight = true;
            },
            getPlantVarietyList() {
                this.http.plantVariety.getPlantVarietyList().then(response => {
                    if (response.data.status == 200) {
                        this.plantVarietyList = response.data.data;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },
            getPlantSeedingsList() {
                this.http.plantSeedlings.getPlantSeedlingsList().then(response => {
                    if (response.data.status == 200) {
                        this.plantSeedlingsList = response.data.data;
                    }
                });
            },
            getUserList() {
                this.http.user.getUserListByOrganizationId().then(response => {
                    if (response.data.status == 200) {
                        this.userList = response.data.data;
                    }
                });
            },
            getPlantWayList() {
                this.http.plantWay.getPlantWayList().then(response => {
                    if (response.data.status == 200) {
                        this.plantWayList = response.data.data;
                    }
                });
            },
            getAssetWarehouseList() {
                this.http.assetWarehouse.getAssetWarehouseList().then(response => {
                    if (response.data.status == 200) {
                        this.assetWarehouseList = response.data.data;
                    }
                });
            },
            addAssetWarehouseAndDosage() {
                if(this.cropManagementForm.assetWarehouseAndDosageList==undefined || this.cropManagementForm.assetWarehouseAndDosageList==null){
                    this.cropManagementForm.assetWarehouseAndDosageList=[];
                }
                this.cropManagementForm.assetWarehouseAndDosageList.push({assetWarehouse: [], dosage: ''})
            },
            removeAllAssetWarehouseAndDosage() {
                this.cropManagementForm.assetWarehouseAndDosageList = [{assetWarehouse: [], dosage: ''}];
            },
            removeOneAssetWarehouseAndDosage(index) {
                this.cropManagementForm.assetWarehouseAndDosageList.splice(index, 1);
            },
            toEditCropManagementState(state){
                this.http.cropManagement.updateCropManagementState({
                    id:this.cropManagementForm.id,
                    state:state,
                }).then(res => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.showRight = false;
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                    this.getCropManagementData();
                });
            },
            toEditCropManagement() {
                this.cropManagementForm.startTimeStr = this.moment(this.cropManagementForm.time[0]).format('YYYY-MM-DD HH:mm:ss');
                this.cropManagementForm.finishTimeStr = this.moment(this.cropManagementForm.time[1]).format('YYYY-MM-DD HH:mm:ss');
                this.cropManagementForm.assetWarehouseAndDosage = (this.cropManagementForm.assetWarehouseAndDosageList!=undefined&&this.cropManagementForm.assetWarehouseAndDosageList!=null
                    &&this.cropManagementForm.assetWarehouseAndDosageList.length>0)?JSON.stringify(this.cropManagementForm.assetWarehouseAndDosageList):undefined;
                this.cropManagementForm.workers = this.cropManagementForm.workersList.map(t => t).join(',');
                this.http.cropManagement.updateCropManagement(this.cropManagementForm).then(res => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.showRight = false;
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                    this.getCropManagementData();
                });
                console.log(this.cropManagementForm);
            },
            toAddCropManagement() {
                this.cropManagementForm.startTimeStr = this.moment(this.cropManagementForm.time[0]).format('YYYY-MM-DD HH:mm:ss');
                this.cropManagementForm.finishTimeStr = this.moment(this.cropManagementForm.time[1]).format('YYYY-MM-DD HH:mm:ss');
                this.cropManagementForm.assetWarehouseAndDosage = (this.cropManagementForm.assetWarehouseAndDosageList!=undefined&&this.cropManagementForm.assetWarehouseAndDosageList!=null
                    &&this.cropManagementForm.assetWarehouseAndDosageList.length>0)?JSON.stringify(this.cropManagementForm.assetWarehouseAndDosageList):undefined;
                this.cropManagementForm.workers = this.cropManagementForm.workersList.map(t => t).join(',');
                this.http.cropManagement.createdCropManagement(this.cropManagementForm).then(res => {
                    if (res.data.status == 200) {
                        this.$message.success(res.data.msg);
                        this.showRight = false;
                    } else {
                        this.$message.warning(res.data.msg);
                    }
                    this.getCropManagementData();
                });
                console.log(this.cropManagementForm);
            },
            addCropManagement() {
                this.rightType = 'add';
                this.cleanCropManagementForm();
                this.showRight = true;
            },
            cleanCropManagementForm() {
                this.cropManagementForm = {
                    id: '',
                    plantSeedingsId: [],
                    workWay: [],
                    plantWayId: [],
                    workersList: [],
                    time: [],
                    startTimeStr: '',
                    finishTimeStr: '',
                    state: [],
                    assetWarehouseAndDosageList: [{assetWarehouse: [], dosage: ''}],
                    assetWarehouseAndDosage: '',
                    plantSeedingsName: '',
                    plantWayName: '',
                    workersList: [],
                };
            },
            getCropManagementData() {
                this.http.cropManagement.getCropManagementListPage({
                    size: this.pageSize,
                    current: this.currentPage,
                    plantVarietyId: this.query.plantVarietyId,
                    plantWayId: this.query.plantWayId,
                    state: this.query.state,
                    groupBy: this.query.groupBy,
                    year: this.selectYear
                }).then(response => {
                    if (response.data.status == 200) {
                        this.data = response.data.data.records;
                        this.totalCount = response.data.data.total;
                        this.currentPage = response.data.data.current;
                    } else {
                        this.$message.warning('数据获取失败');
                    }
                });
            },
            handlePageChange(val) {
                this.spinning = true;
                this.currentPage = val;
                this.getCropManagementData();
            },
            handleSizeChange(val) {
                this.spinning = true;
                this.pageSize = val;
                this.getCropManagementData();
            },
            handleSearch() {
                this.showRight = false;
                this.spinning = true;
                console.log(this.selectYear);
                this.getCropManagementData();
            },
        }
    }
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: rgba(203, 203, 203, 0.8);
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  .dropdown_class {
    color: #02070d;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .need-asset-warehouse-delete {
    color: #4c4c4c;
  }

  .need-asset-warehouse-delete:hover {
    color: red;
  }

  a {
    color: black;
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-selection {
    border: 0px solid #d9d9d9;
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-selection {
    background: transparent;
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-selection:hover {
    background: transparent;
    border-right-width: 0px !important;
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-selection-selected-value {
    color: black;
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-arrow {
    visibility: hidden
  }

  .assetWarehouseAndDosage_look_class >>> .ant-select-selection__choice {
    color: black;
    background: transparent;
    border: 0px solid #e8e8e8;
  }

</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
